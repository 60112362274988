<template>
  <el-dialog
    title="基本信息编辑"
    :visible.sync="dialogVisible"
    width="820px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    class="base-info-edit-dialog"
    @closed="handleCancel"
  >
    <div v-loading="formLoading">
        <el-form
            :model="baseInfoForm"
            :rules="baseInfoFormRules"
            ref="baseInfoForm"
            label-width="82px"
            label-position="right"
            class="base-info-form"
        >
            <div class="row-title">
                <span></span>
                <i>基本信息</i>
            </div>

            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item prop="realName" label="姓名" required>
                        <el-input
                            class="input-item"
                            v-model="baseInfoForm.realName"
                        ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-row :gutter="0">
                        <el-col :span="12">
                            <el-form-item class="gender-form-item" prop="gender" label="性别">
                                <el-select
                                    popper-class="select-list-dropdown"
                                    v-model="baseInfoForm.gender"
                                >
                                    <el-option
                                        label="未知"
                                        :value="0"
                                        disabled
                                    ></el-option>
                                    <el-option
                                        label="男"
                                        :value="1"
                                    ></el-option>
                                    <el-option
                                        label="女"
                                        :value="2"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                class="select-form-item"
                                prop="city"
                                label="城市"
                                :required="!isPersonalResume"
                            >
                                <el-cascader
                                    :props="{expandTrigger:'hover'}"
                                    :options="cities"
                                    :show-all-levels="false"
                                    :clearable="true"
                                    v-model="select.selectedCity"
                                    @change="selectCity"
                                ></el-cascader>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>

            <el-row :gutter="20">
                <el-col v-if="mobileStatus == 3 || mobileStatus == 4" :span="12">
                    <el-form-item id="input-mobile" prop="mobile" label="手机号码" required>
                        <el-input
                            class="input-item"
                            v-model="baseInfoForm.mobile"
                            placeholder="国际手机以+或者00开头"
                            @input.native="regexMobile"
                        ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12" v-else>
                    <el-form-item id="input-mobile" prop="mobile" label="手机号码" required>
                        <el-input
                            disabled
                            class="input-item"
                            value="***********"
                        ></el-input>
                        <span 
                            v-if="mobileStatus == 1" 
                            class="view-mobile-btn" 
                            @click="applyView(1)"
                            v-loading="viewMobileLoading"
                        >查看手机</span>
                        <el-tooltip
                            placement="bottom"
                            effect="light"
                            popper-class="concat-tooltip"
                            :visible-arrow="false"
                            v-if="mobileStatus == 2"
                        >
                            <div slot="content">
                                候选人正在入职保证期中，暂不能查看其联<br/>系方式
                            </div>
                            <span class="view-mobile-btn lock">
                                <!-- 查看手机 -->
                                <i class="icon-font icon-suo"></i>
                            </span>
                        </el-tooltip>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-row :gutter="0">
                        <el-col :span="12">
                            <el-form-item prop="yearOfExperience" class="yexp-form-item" label="工作经验">
                                <span class="text-slot">年</span>
                                <el-input v-model="baseInfoForm.yearOfExperience">
                                    <!-- <template slot="append">年</template> -->
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item prop="age" class="yexp-form-item" label="年龄">
                                <span class="text-slot">岁</span>
                                <el-input v-model="baseInfoForm.age">
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
            
            <el-row :gutter="20">
                <el-col v-if="emailStatus == 3 || emailStatus == 4" :span="12">
                    <el-form-item id="input-email" prop="email" label="邮箱" :required="isRecommend">
                        <el-input
                            class="input-item"
                            v-model.trim="baseInfoForm.email"
                        ></el-input>
                    </el-form-item>
                </el-col>
                <el-col v-else :span="12">
                    <el-form-item id="input-email" prop="email" label="邮箱" :required="isRecommend">
                        <el-input
                            disabled
                            value="******"
                            class="input-item"
                        ></el-input>
                        <span 
                            v-if="emailStatus == 1" 
                            class="view-mobile-btn" 
                            @click="applyView(2)"
                            v-loading="viewEmailLoading"
                        >查看邮箱</span>
                        <el-tooltip
                            placement="bottom"
                            effect="light"
                            popper-class="concat-tooltip"
                            :visible-arrow="false"
                            v-if="emailStatus == 2"
                        >
                            <div slot="content">
                                候选人正在入职保证期中，暂不能查看其联<br/>系方式
                            </div>
                            <span class="view-mobile-btn lock">
                                <!-- 查看手机 -->
                                <i class="icon-font icon-suo"></i>
                            </span>
                        </el-tooltip>
                    </el-form-item>
                </el-col>

                <el-col v-if="wechatStatus == 3 || wechatStatus == 4" :span="12">
                    <el-form-item id="input-wechat" prop="wechat" label="微信">
                        <el-input
                            class="input-item"
                            v-model.trim="baseInfoForm.wechat"
                            placeholder="微信长度为6~20位"
                        ></el-input>
                    </el-form-item>
                </el-col>
                <el-col v-else :span="12">
                    <el-form-item id="input-wechat" prop="wechat" label="微信">
                        <el-input
                            disabled
                            class="input-item"
                            value="******"
                        ></el-input>
                        <span 
                            v-if="wechatStatus == 1" 
                            class="view-mobile-btn" 
                            @click="applyView(3)"
                            v-loading="viewWechatLoading"
                        >查看微信</span>
                        <el-tooltip
                            placement="bottom"
                            effect="light"
                            popper-class="concat-tooltip"
                            :visible-arrow="false"
                            v-if="wechatStatus == 2"
                        >
                            <div slot="content">
                                候选人正在入职保证期中，暂不能查看其联<br/>系方式
                            </div>
                            <span class="view-mobile-btn lock">
                                <!-- 查看手机 -->
                                <i class="icon-font icon-suo"></i>
                            </span>
                        </el-tooltip>
                    </el-form-item>
                </el-col>
            </el-row>

            <div class="row-title work-row-title">
                <span></span>
                <i>工作经历</i>

                <el-checkbox v-if="hasWorkModified" class="checkbox-item" v-model="baseInfoForm.isAddWork">添加为最新的工作经历<i class="add-work-tip">(新增一段最近工作经历时，请记得勾选)</i></el-checkbox>
            </div>

            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item prop="company" label="公司名称" :required="!isPersonalResume">
                        <el-input
                            v-if="baseInfoForm.isFirmResume!=undefined && !baseInfoForm.isFirmResume"
                            class="input-item"
                            v-model="baseInfoForm.company"
                            @input="handleHasWorkModified"
                        ></el-input>
                        <customer-select-or-write
                            v-else
                            class="input-item"
                            :customerId.sync="baseInfoForm.customerId"
                            :customerName.sync="baseInfoForm.company"
                            @finish-callback="finishCallback"
                            />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item prop="title" label="职位"  :required="!isPersonalResume">
                        <el-input
                            class="input-item"
                            v-model="baseInfoForm.title"
                            @input="handleHasWorkModified"
                        ></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            
            <el-row :gutter="20">
                <el-col :span="12" v-if="baseInfoForm.customerId && (baseInfoForm.isFirmResume || baseInfoForm.fromRecommendDetail)">
                    <el-form-item prop="customerDepartmentId" label="部门">
                        <customer-department-select
                            ref="departmentSelect"
                            :customerId="baseInfoForm.customerId"
                            :departmentId="baseInfoForm.customerDepartmentId"
                            :customerDepartmentName="baseInfoForm.customerDepartmentName"
                            placeholder='输入完整路径，多级部门用"，"分隔'
                            @update-department="updateDepartment"
                        ></customer-department-select>
                    </el-form-item>
                </el-col>
                
                <el-col :span="12" class="start-date-wrap">
                    <el-form-item label="时间" prop="workDateRange" :required="!isPersonalResume">
                        <div class="date-picker-pannel">
                            <font-icon href="#icon-calendar"></font-icon>
                            <div class="date-picker-wrap">
                                <el-date-picker
                                    v-model="baseInfoForm.workStartDate"
                                    type="month"
                                    placeholder="开始时间"
                                    :picker-options="setExperienceStartDateOption()"
                                    @change="(picker) => dateBlur(picker, 'start', 'work')"
                                    @blur="(picker) => dateBlur(picker, 'start', 'work')">
                                </el-date-picker>
                            </div>
                            <span class="date-picker-split">~</span>
                            <div class="date-picker-wrap">
                                <div class="date-tonow"
                                    v-if="baseInfoForm.workEndDate && baseInfoForm.workEndDate.toString().indexOf('9999')>-1">
                                    至今
                                </div>
                                <el-date-picker
                                    v-if="baseInfoForm.workEndDate && baseInfoForm.workEndDate.toString().indexOf('9999')>-1"
                                    v-model="baseInfoForm.workEndDate"
                                    type="month"
                                    placeholder="结束时间"
                                    :picker-options="setExperienceEndDateOption()"
                                    class="opacity-date-picker"
                                ></el-date-picker>
                                <el-date-picker
                                    v-if="!baseInfoForm.workEndDate || baseInfoForm.workEndDate.toString().indexOf('9999')===-1"
                                    v-model="baseInfoForm.workEndDate"
                                    type="month"
                                    placeholder="结束时间"
                                    :picker-options="setExperienceEndDateOption()"
                                    @change="(picker) => dateBlur(picker, 'end', 'work')"
                                    @blur="(picker) => dateBlur(picker, 'end', 'work')">
                                </el-date-picker>
                            </div>
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>

            <div class="row-title work-row-title">
                <span></span>
                <i>教育经历</i>

                <el-checkbox v-if="hasEducationModified" class="checkbox-item" v-model="baseInfoForm.isAddEducation">添加为最新的教育经历<i class="add-work-tip">(新增一段教育经历时，请记得勾选)</i></el-checkbox>
            </div>

            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item class="university-chose" prop="school" label="毕业院校" :required="!isPersonalResume">
                        <el-select
                            popper-class="select-list-dropdown"
                            v-model="baseInfoForm.school"
                            filterable
                            allow-create
                            @change="(select)=>selectChange(select, 'school')"
                        >
                            <el-option
                                v-for="item in universityList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.name"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item class="degree-chose" prop="degree" label="学历" :required="!isPersonalResume">
                        <el-select
                            popper-class="select-list-dropdown"
                            v-model="baseInfoForm.degree"
                            placeholder="请选择"
                        >
                            <el-option
                                v-for="item in degreeArr"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                                :disabled="item.label == '未知'"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item class="major-chose" prop="major" label="专业" :required="!isPersonalResume">
                        <el-select
                            popper-class="select-list-dropdown"
                            v-model="baseInfoForm.major"
                            filterable
                            allow-create
                            @change="(select)=>selectChange(select, 'major')"
                        >
                            <el-option
                                v-for="item in majorList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.name"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="12" class="start-date-wrap">
                    <el-form-item label="时间" prop="educationDateRange" :required="!isPersonalResume">
                        <div class="date-picker-pannel">
                            <font-icon href="#icon-calendar"></font-icon>
                            <div class="date-picker-wrap">
                                <el-date-picker
                                    v-model="baseInfoForm.educationStartDate"
                                    type="month"
                                    placeholder="开始时间"
                                    :picker-options="setEducationStartDateOption()"
                                    @change="(picker) => dateBlur(picker, 'start', 'education')"
                                    @blur="(picker) => dateBlur(picker, 'start', 'education')">
                                </el-date-picker>
                            </div>
                            <span class="date-picker-split">~</span>
                            <div class="date-picker-wrap">
                                <div class="date-tonow"
                                    v-if="baseInfoForm.educationEndDate && baseInfoForm.educationEndDate.toString().indexOf('9999')>-1">
                                    至今
                                </div>
                                <el-date-picker
                                    v-if="baseInfoForm.educationEndDate && baseInfoForm.educationEndDate.toString().indexOf('9999')>-1"
                                    v-model="baseInfoForm.educationEndDate"
                                    type="month"
                                    placeholder="结束时间"
                                    :picker-options="setEducationEndDateOption()"
                                    class="opacity-date-picker"
                                ></el-date-picker>
                                <el-date-picker
                                    v-if="!baseInfoForm.educationEndDate || baseInfoForm.educationEndDate.toString().indexOf('9999')===-1"
                                    v-model="baseInfoForm.educationEndDate"
                                    type="month"
                                    placeholder="结束时间"
                                    :picker-options="setEducationEndDateOption()"
                                    @change="(picker) => dateBlur(picker, 'end', 'education')"
                                    @blur="(picker) => dateBlur(picker, 'end', 'education')">
                                </el-date-picker>
                            </div>
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>
            
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="handleCancel">取消</el-button>
            <el-button type="primary" @click="confirmHandle">保存</el-button>
        </div>
    </div>

    <el-dialog
        :title="scoreFeedbackType == 1 ? '请输入真实有效的电话号码' : '提示'"
        :visible.sync="scoreFeedbackDialogVisible"
        width="480px"
        class="score-feedback-dialog"
        :show-close="true"
        :close-on-click-modal="false"
        :append-to-body="true"
    >
        <div>
            <span v-if="scoreFeedbackType == 1" class="tip">请输入真实有效的电话号码，剩余<i>{{ scoreFeedbackLimit }}</i>次填写机会。</span>
            <span v-if="scoreFeedbackType == 2" class="tip">电话号码输入错误已超过<i>5</i>次，今日无法再对本简历进行更新。</span>
            <span v-if="scoreFeedbackType == 3" class="tip">今日无法再对本简历进行更新。</span>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button class="score-feedback-btn" type="primary" @click="scoreFeedbackDialogVisible = false;">知道了</el-button>
        </span>
    </el-dialog>
  </el-dialog>
</template>

<script>
import moment from 'moment';
import provinceData from '#/js/config/provinceThreeData.json';
import degreeData from '#/js/config/degreeData.json';
import cityProvinceMapData from '#/js/config/cityProvinceMap.json';
import resumeSingleService from '#/js/service/resumeSingleService.js';
import CustomerDepartmentSelect from '#/component/common/customer-department-select.vue'
import CustomerSelectOrWrite from '#/component/common/customer-select-or-write.vue';
import { candidate as candidateUrl } from '#/js/config/api.json';

export default {
    components: {
        CustomerDepartmentSelect,
        CustomerSelectOrWrite,
    },
    props: {
        isPersonalResume: false,
        isRecommend: false,
        isSaveToDb: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            dialogVisible: false,
            candidateId: '',
            baseInfoForm: {
                realName: "",
                age: '',
                gender: 0,
                mobile: null,
                email: null,
                wechat: null,
                city: "",
                yearOfExperience: "",
                company: "",
                title: "",
                workStartDate: '',
                workEndDate: '',
                workDateRange: '',
                isAddWork: false,
                school: "",
                major: "",
                degree: "",
                educationStartDate: '',
                educationEndDate: '',
                educationDateRange: '',
                isAddEducation: false,
                customerId: "",
                customerDepartmentName: "",
                customerDepartmentId: "",
                isFirmResume: '',
                fromRecommendDetail: '',
            },
            baseInfoFormRules: {
                realName: [
                    { required: true, message: '姓名不能为空', trigger: ['blur', 'change'] },
                    { min: 2, message: '不能少于 2 个字符', trigger: ['blur', 'change'] },
                    { max: 50, message: '不能超过 50 个字符', trigger: ['blur', 'change'] },
                    { validator: this.validDangeroutStr, trigger: ['blur', 'change'] },
                    { validator: this.validateRealNameScore, trigger: ['blur'] }
                ],
                yearOfExperience: [
                    { validator: this.validateYearOfExperience, trigger: ['blur', 'change'] }
                ],
                age: [
                    { validator: this.validateAge, trigger: ['blur', 'change'] }
                ],
                city: [
                    { validator: this.validateCity, trigger: ['blur', 'change'] }
                ],
                mobile: [
                    { required: true, message: '请输入手机号', trigger: ['blur', 'change'] },
                    { validator: this.validateMobile, trigger: ['blur', 'change'] },
                    { validator: this.validateMobileScore, trigger: ['blur'] }
                ],
                email: [
                    { validator: this.validateEmail, trigger: ['blur', 'change', 'submit'] }
                ],
                wechat: [
                    { validator: this.validateWechat, trigger: ['blur', 'change'] }
                ],
                company: [
                    { validator: this.validateCompany, trigger: ['blur', 'change'] }
                ],
                title: [
                    { validator: this.validateTitle, trigger: ['blur', 'change'] }
                ],
                school: [
                    { min: 1, max: 128, message: '不能超过 128 个字符', trigger: ['blur', 'change'] },
                    { validator: this.validSchool, trigger: ['blur', 'change'] }
                ],
                major: [
                    { min: 1, max: 128, message: '不能超过 128 个字符', trigger: ['blur', 'change'] },
                    { validator: this.validMajor, trigger: ['blur', 'change'] }
                ],
                degree: [
                    { validator: this.validDegree, trigger: ['blur', 'change'] }
                ],
                workDateRange: [
                    // { required: true, message: '请输入工作经历时间', trigger: ['blur', 'change'] },
                    { validator: this.validWorkDateRange, trigger: ['change'] }
                ],
                educationDateRange: [
                    // { required: true, message: '请输入教育经历时间', trigger: ['blur', 'change'] },
                    { validator: this.validEducationDateRange, trigger: ['change'] }
                ]
            },
            canViewContract: false,
            mobileStatus: null,
            emailStatus: null,
            wechatStatus: null,
            hasEducationModified: false,
            hasWorkModified: false,
            formLoading: false,
            cities: provinceData.province,
            select: {
                selectedCity: []
            },
            universityList: [],
            majorList: [],
            degreeArr: degreeData.degreeArr,
            originBirthday: '',
            
            openFrom: '', //从哪里打开，目前对比页面不让显示 hasWorkModified 的内容
            workStartDateError: false,
            workEndDateError: false,
            educationStartDateError: false,
            educationEndDateError: false,

            viewMobileLoading: false,
            viewEmailLoading: false,
            viewWechatLoading: false,

            scoreFeedbackType: 1,
            scoreFeedbackLimit: 0,
            scoreFeedbackDialogVisible: false,
            lastInvalidMobile: ''
        }
    },
    computed: {
        token(){
            return this.$store.state.verificationToken.verificationToken;
        },
    },
    methods: {
        updateDepartment(department = {}) {
            this.baseInfoForm.customerDepartmentId = department.id;
            this.baseInfoForm.customerDepartmentName = department.departmentName;
        },
        finishCallback () {
            this.baseInfoForm.customerDepartmentId = null;
            this.baseInfoForm.customerDepartmentName = '';
            this.handleHasWorkModified();
            this.$nextTick(() => {
                this.$refs.departmentSelect && this.$refs.departmentSelect.reset();
            });
        },
        show(candidateDetail) {
            this.getUniversityList();
            this.getMajorList();
            this.candidateId = candidateDetail.id;
            this.mergeFormData(candidateDetail);
            this.originBirthday = candidateDetail.birthday || '';
            this.dialogVisible = true;
        },
        mergeFormData(candidateDetail) {
            console.log(candidateDetail)
            let workExperience = candidateDetail.workExperiences && candidateDetail.workExperiences.length ? candidateDetail.workExperiences[0] : {};
            let educationExperience = candidateDetail.educationExperiences && candidateDetail.educationExperiences.length ? candidateDetail.educationExperiences[0] : {};

            if(candidateDetail.email === 'xxx@xx.com'){
                candidateDetail.email = '';
            }
            let baseInfoObj = {
                realName: "",
                gender: 0,
                mobile: "",
                email: "",
                wechat: "",
                city: "",
                province: "",
                yearOfExperience: "",
                company: "",
                title: "",
                school: "",
                major: "",
                degree: "",
                customerId: "",
                customerDepartmentName: "",
                customerDepartmentId: "",
                isFirmResume: "",
                fromRecommendDetail: "",    // 从推荐页面过来
            }
            Object.keys(baseInfoObj).forEach(key => {
                baseInfoObj[key] = candidateDetail[key];
            });
            Object.assign(this.baseInfoForm, baseInfoObj);
            if(this.baseInfoForm.customerId == '0') {
                this.baseInfoForm.customerId = null;
            }
            if(candidateDetail.city && !candidateDetail.province) {
                candidateDetail.province = parseInt(this.getProvinceByCity(candidateDetail.city).id);
            }
            this.$set(this.select, "selectedCity", [candidateDetail.province, candidateDetail.city]);
            this.baseInfoForm.id = this.isPersonalResume ? "" : candidateDetail.id;
            this.baseInfoForm.workId = workExperience.id || '';
            if (workExperience.startDate && workExperience.startDate.indexOf('0001-01-01') != 0) {
                this.baseInfoForm.workStartDate = workExperience.startDate || '';
            }
            if (workExperience.endDate && workExperience.endDate.indexOf('0001-01-01') != 0) {
                this.baseInfoForm.workEndDate = workExperience.endDate || '';
            }
            if (this.baseInfoForm.workStartDate && this.baseInfoForm.workEndDate) {
                this.baseInfoForm.workDateRange = '1';
            }
            this.baseInfoForm.educationId = educationExperience.id || '';
            if (educationExperience.startDate && educationExperience.startDate.indexOf('0001-01-01') != 0) {
                this.baseInfoForm.educationStartDate = educationExperience.startDate || '';
            }
            if (educationExperience.endDate && educationExperience.endDate.indexOf('0001-01-01') != 0) {
                this.baseInfoForm.educationEndDate = educationExperience.endDate || '';
            }
            if (this.baseInfoForm.educationStartDate && this.baseInfoForm.educationEndDate) {
                this.baseInfoForm.educationDateRange = '1';
            }
            this.baseInfoForm.resumeId = candidateDetail.tbdResumeId;
            if(candidateDetail.birthday && candidateDetail.birthday.indexOf('0001-01-01') != 0) {
                this.baseInfoForm.age = (new Date()).getFullYear() - candidateDetail.birthday.slice(0, 4);
            }
            this.canViewContract = candidateDetail.canViewContract || false;
            this.mobileStatus = candidateDetail.mobileStatus;
            if (this.mobileStatus == 1 || this.mobileStatus == 2) {
                this.baseInfoForm.mobile = '******';
            }
            this.emailStatus = candidateDetail.emailStatus;
            this.wechatStatus = candidateDetail.wechatStatus;

            this.openFrom = candidateDetail.openFrom;
            
            this.$nextTick(() => {
                if(this.$refs.departmentSelect && this.baseInfoForm.customerDepartmentId){
                    this.$refs.departmentSelect.value = this.baseInfoForm.customerDepartmentId;
                    this.$refs.departmentSelect.editInit({
                        id: this.baseInfoForm.customerDepartmentId
                    });
                }
            })
        },
        applyView(type) {
            let list = {
                1: 'Mobile',
                2: 'Email',
                3: 'Wechat'
            }
            const data = {
                type: type,
                __RequestVerificationToken: this.token
            };
            const url = candidateUrl.url_log_view_contact.replace(/%p/, this.candidateId);
            this[`view${list[type]}Loading`] = true;

            _request({
                url: url,
                method: "POST",
                data: data
            }).then(res => {
                this[`view${list[type]}Loading`] = false;

                switch (list[type]) {
                    case 'Wechat':
                        this.baseInfoForm.wechat = res.contact;
                        this.wechatStatus = res.isValid ? 3 : 4;
                        break;
                    case 'Email':
                        this.baseInfoForm.email = res.contact;
                        this.emailStatus = res.isValid ? 3 : 4;
                        break;
                    case 'Mobile':
                        this.baseInfoForm.mobile = res.contact;
                        this.mobileStatus = res.isValid ? 3 : 4;
                        break;
                    default:
                        break;
                }

                this.$emit('apply-view-success', list[type], res);
            }).catch(err => {
                if(err.message || err.errorMeaasge) {
                    shortTip(err.message || err.errorMeaasge)
                } else {
                    shortTip('系统异常，请稍后重试')
                }
                

                this[`view${list[type]}Loading`] = false;
            })
        },
        //日期控件配置
        setExperienceStartDateOption(){
            let experience = this.baseInfoForm;
            experience.workStartDate = experience.workStartDate==='' ? null : experience.workStartDate;
            experience.workEndDate = experience.workEndDate==='' ? null : experience.workEndDate;
            if(typeof experience.workEndDate==='string'){
                experience.workEndDate = new Date(experience.workEndDate.split('T')[0]);
            }
            return {
                disabledDate: (time) => {
                    if (!!experience.workEndDate) {
                        return time.getTime() > Date.now() || time.getTime() > experience.workEndDate.getTime();
                    } else {
                        return time.getTime() > Date.now();
                    }
                }
            }
        },
        setExperienceEndDateOption(){
            let experience = this.baseInfoForm;
            experience.workStartDate = experience.workStartDate === '' ? null : experience.workStartDate;
            experience.workEndDate = experience.workEndDate === '' ? null : experience.workEndDate;
            if(typeof experience.workStartDate==='string'){
                experience.workStartDate = new Date(experience.workStartDate.split('T')[0]);
            }
            let _this = this;
            return {
                disabledDate: (time) => {
                    if (!!experience.workStartDate) {
                        return time.getTime() < experience.workStartDate.getTime() || time.getTime() > Date.now();
                    }
                },
                shortcuts: [{
                    text: '至今',
                    onClick(picker) {
                        let date = new Date('9999-12-31');
                        picker.$emit('pick', date);

                        if (_this.baseInfoForm.workStartDate) {
                            _this.baseInfoForm.workDateRange = '1';
                        } else {
                            _this.baseInfoForm.workDateRange = '';
                        }
                    }
                },{
                    text: '今天',
                    onClick(picker) {
                        picker.$emit('pick', new Date());

                        if (_this.baseInfoForm.workStartDate) {
                            _this.baseInfoForm.workDateRange = '1';
                        } else {
                            _this.baseInfoForm.workDateRange = '';
                        }
                    }
                }]
            }
        },
        setEducationStartDateOption(){
            let experience = this.baseInfoForm;
            experience.educationStartDate = experience.educationStartDate==='' ? null : experience.educationStartDate;
            experience.educationEndDate = experience.educationEndDate==='' ? null : experience.educationEndDate;
            if(typeof experience.educationEndDate==='string'){
                experience.educationEndDate = new Date(experience.educationEndDate.split('T')[0]);
            }
            return {
                disabledDate: (time) => {
                    if (!!experience.educationEndDate) {
                        return time.getTime() > experience.educationEndDate.getTime();
                    }
                }
            }
        },
        setEducationEndDateOption(){
            let experience = this.baseInfoForm;
            experience.educationStartDate = experience.educationStartDate==='' ? null : experience.educationStartDate;
            experience.educationEndDate = experience.educationEndDate==='' ? null : experience.educationEndDate;
            if(typeof experience.educationStartDate==='string'){
                experience.educationStartDate = new Date(experience.educationStartDate.split('T')[0]);
            }
            let _this = this;
            return {
                disabledDate: (time) => {
                    if (!!experience.educationStartDate) {
                        return time.getTime() < experience.educationStartDate.getTime();
                    }
                },
                shortcuts: [{
                    text: '至今',
                    onClick(picker) {
                        let date = new Date('9999-12-31');
                        picker.$emit('pick', date);

                        if (_this.baseInfoForm.educationStartDate) {
                            _this.baseInfoForm.educationDateRange = '1';
                        } else {
                            _this.baseInfoForm.workDateRange = '';
                        }
                    }
                },{
                    text: '今天',
                    onClick(picker) {
                        picker.$emit('pick', new Date());

                        if (_this.baseInfoForm.educationStartDate) {
                            _this.baseInfoForm.educationDateRange = '1';
                        } else {
                            _this.baseInfoForm.workDateRange = '';
                        }
                    }
                }]
            }
        },
        dateBlur(picker, type, blockType){
            if (blockType == 'work') {
                if (this.baseInfoForm.workStartDate && this.baseInfoForm.workEndDate) {
                    this.baseInfoForm.workDateRange = '1';
                } else {
                    this.baseInfoForm.workDateRange = '';
                }
            }

            if (blockType == 'education') {
                if (this.baseInfoForm.educationStartDate && this.baseInfoForm.educationEndDate) {
                    this.baseInfoForm.educationDateRange = '1';
                } else {
                    this.baseInfoForm.educationDateRange = '';
                }
            }

            var dateReg = /^[0-9]{1,4}[年.\-/]((0?[1-9])|(1[0-2]))月?$/;
            var inputValue = picker && picker.$children && picker.$children[0].currentValue;
            if(type==='start'){
                if(!dateReg.test(inputValue) && !!inputValue){
                    if (blockType == 'work') {
                        this.workStartDateError = true;
                    }
                    if (blockType == 'education') {
                        this.educationStartDateError = true;
                    }
                }else{
                    if (blockType == 'work') {
                        this.workStartDateError = false;
                    }
                    if (blockType == 'education') {
                        this.educationStartDateError = false;
                    }
                }
            }else if((type==='end')){
                if(!dateReg.test(inputValue) && !!inputValue){
                    if (blockType == 'work') {
                        this.workEndDateError = true;
                    }
                    if (blockType == 'education') {
                        this.educationEndDateError = true;
                    }
                }else{
                    if (blockType == 'work') {
                        this.workEndDateError = false;
                    }
                    if (blockType == 'education') {
                        this.educationEndDateError = false;
                    }
                }
            }
            setTimeout(function(){
                inputValue = picker && picker.$children && picker.$children[0].currentValue;
                if(!!inputValue && inputValue.indexOf('9999')>-1){
                    this.workEndDateError = false;
                }
            },500)
        },
        getUniversityList(){
            var _self = this;
            resumeSingleService.getUniversityList()
            .then((res)=>{
                this.universityList = res;
            });
        },
        getMajorList(){
            var _self = this;
            resumeSingleService.getMajorList()
            .then((res)=>{
                this.majorList = res;
            })
        },
        getProvinceByCity(cid){
            let pId = cityProvinceMapData[cid];
            return {
                id: pId
            };
        },
        regexMobile(ev){
            let val = ev.target.value;
            val = val.replace(/\-/g,'');
            this.$set(this.baseInfoForm, 'mobile', val);
        },
        selectCity(val){
            this.baseInfoForm.province = val[0];
            this.baseInfoForm.city = val[1];
        },
        selectChange(select, type){
            if(!this.openFrom) {
                this.hasEducationModified = true;
            }
            if(type === 'school'){
                if(this.universityList.every((item)=>{ return item.name!==select})) {
                    this.universityList.push({name: select})
                }
            } else if(type === 'major'){
                if(this.majorList.every((item)=>{ return item.name!==select})) {
                    this.majorList.push({name: select})
                }
            }
        },
        handleHasWorkModified(){
            if(!this.openFrom) {
                this.hasWorkModified = true;
            }
        },
        validateMobile(rule, value, callback) {
            if (value == '******' && (this.mobileStatus == 1 || this.mobileStatus == 2)) {
                callback();
                return;
            }

            let regexMobileExternal = /^(\+|00)\d{8,15}$/,
                regexMobileInternal = /^(1[3-9])\d{9}$/;
            let arr = value.match(regexMobileExternal) || value.match(regexMobileInternal);
            if(arr === null) {
                callback(new Error('请填写有效的国内/国际手机号码'));
            } else {
                callback();
            }
        },
        validateMobileScore(rule, value, callback) {
            if (this.isPersonalResume) {
                callback();
                return;
            }

            if (this.mobileStatus == 1 || this.mobileStatus == 2) {
                callback();
                return;
            }

            if (value && this.lastInvalidMobile && value == this.lastInvalidMobile) {
                callback(new Error("请输入真实有效的手机号"));
                return;
            }
            
            console.log('is valid');
            resumeSingleService.validMobileScore({
                resumeId: this.baseInfoForm.resumeId,
                mobile: value
            }).then(res => {
                callback();
                return;
            }).catch(err => {
                if (err.succeeded) {
                    callback();
                    return;
                } else {
                    if (err.data > 0) {
                        this.scoreFeedbackType = 1;
                        this.scoreFeedbackLimit = err.data || 0;
                        this.scoreFeedbackDialogVisible = true;
                    } else if (err.data == 0) {
                        this.scoreFeedbackType = 2;
                        this.scoreFeedbackDialogVisible = true;
                    } else if (err.data == -1) {
                        this.scoreFeedbackType = 3;
                        this.scoreFeedbackDialogVisible = true;
                    }

                    this.lastInvalidMobile = value;

                    callback(new Error("请输入真实有效的手机号"));
                    return;
                }
            }).finally(() => {
                console.log('is valid end');
            })
        },
        validateRealNameScore(rule, value, callback) {
            if (this.isPersonalResume) {
                callback();
                return;
            }
            resumeSingleService.validRealNameScore({
                realName: value
            }).then(res => {
                callback();
                return;
            }).catch(err => {
                callback(new Error("请输入真实有效的姓名"));
                return;
            })
        },
        validateEmail(rule, value, callback) {
            if(this.isRecommend) {
                if(!value && this.emailStatus != 1 && this.emailStatus != 2) {
                    callback(new Error('请输入邮箱'));
                }
            }
            // let emailReg = /(^\s*[a-zA-Z0-9_%+#&'*/=^`{|}~-](?:\.?[a-zA-Z0-9_%+#&'*/=^`{|}~-])*@(?:[a-zA-Z0-9_](?:(?:\.?|-*)[a-zA-Z0-9_])*\.[a-zA-Z]{2,9}|\[(?:2[0-4]\d|25[0-5]|[01]?\d\d?)\.(?:2[0-4]\d|25[0-5]|[01]?\d\d?)\.(?:2[0-4]\d|25[0-5]|[01]?\d\d?)\.(?:2[0-4]\d|25[0-5]|[01]?\d\d?)])\s*$)|(^(1[3-9])\d{9}$)/;
            let emailReg = /^[A-Za-z0-9\u4e00-\u9fa5\._-]+@[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(?:\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/;
            if(value && value.length > 50) {
                callback(new Error('邮箱最长支持50个字符'));
            } else if(value && !emailReg.test(value)) {
                callback(new Error('请输入正确的电子邮箱'));
            } else {
                callback();
            }
        },
        validateWechat(rule, value, callback) {
            let wechatReg = /^\S{6,20}$/;
            if(value && !wechatReg.test(value)) {
                callback(new Error('微信的长度限制为6~20位'));
            } else {
                callback();
            }
        },
        validateCompany(rule, value, callback) {
            if(!this.isPersonalResume) {
                if(!value) {
                    callback(new Error('公司名不能为空'));
                }
            }
            if(value) {
                if(value.length < 2) {
                    callback(new Error('不能少于 2 个字符'));
                }
                if(value.length > 128) {
                    callback(new Error('不能超过 128 个字符'));
                }
            }
            this.validDangeroutStr(rule, value, callback);
        },
        validateTitle(rule, value, callback) {
            if(!this.isPersonalResume) {
                if(!value) {
                    callback(new Error('职位不能为空'));
                }
            }
            if(value) {
                if(value.length < 2) {
                    callback(new Error('不能少于 2 个字符'));
                }
                if(value.length > 128) {
                    callback(new Error('不能超过 128 个字符'));
                }
            }
            this.validDangeroutStr(rule, value, callback);
        },
        validateYearOfExperience(rule, value, callback) {
            let reg = /^[0-9]\d*$/;
            if(!reg.test(value)) {
                callback(new Error("工作经验必须为整数"));
            } else if(this.isRecommend && value == '0'){
                callback(new Error("工作经验不能为0"))
            } else if(parseInt(value) > 99 || parseInt(value) < 0) {
                callback(new Error("工作经验的范围为0~99"))
            } else {
                callback();
            }
        },
        validateAge(rule, value, callback) {
            let reg = /^[0-9]\d*$/;
            if(value == '') {
                callback();
            } else if(!reg.test(value)) {
                callback(new Error("年龄必须为整数"));
            } else if(value == '0'){
                callback(new Error("年龄不能为0"))
            } else if(parseInt(value) > 99 || parseInt(value) < 0) {
                callback(new Error("年龄的范围为1~99"))
            } else {
                callback();
            }
        },
        validateCity(rule, value, callback) {
            if(!this.isPersonalResume) {
                if(!value) {
                    callback(new Error('城市不能为空'));
                } else {
                    callback();
                }
            } else {
                callback();
            }
        },
        validDangeroutStr(rule, value, callback) {
            let dangerousStringReg = /(\<[a-zA-Z\!\/\?])|(&#)/;
            if(dangerousStringReg.test(value)) {
                callback(new Error('您输入的内容包含非法字符'));
            } else {
                callback();
            }
        },
        validSchool(rule, value, callback) {
            if(value?.trim() == '' || !value) {
                callback(new Error("毕业院校不能为空"));
            }

            if(value) {
                if(value.length < 2) {
                    callback(new Error('不能少于 2 个字符'));
                }
                if(value.length > 128) {
                    callback(new Error('不能超过 128 个字符'));
                }
            }
            this.validDangeroutStr(rule, value, callback);
        },
        validMajor(rule, value, callback) {
            if(value?.trim() == '' || !value) {
                callback(new Error("专业不能为空"));
            }
            
            if(value) {
                if(value.length < 2) {
                    callback(new Error('不能少于 2 个字符'));
                }
                if(value.length > 128) {
                    callback(new Error('不能超过 128 个字符'));
                }
            }
            this.validDangeroutStr(rule, value, callback);
        },
        validDegree(rule, value, callback) {
            if(this.isRecommend) {
                if(value == '0') {
                    callback(new Error("学历不能为未知项"));
                }
            }
            this.validDangeroutStr(rule, value, callback);
        },
        validWorkDateRange(rule, value, callback) {
            if (this.isPersonalResume) {
                callback();
                return;
            } else {
                if (value == '') {
                    callback(new Error('请输入工作经历时间'));
                    return;
                }

                if (this.baseInfoForm.workStartDate && this.baseInfoForm.workEndDate) {
                    if (this.baseInfoForm.workEndDate.getFullYear() == '9999') {
                        if (moment() - moment(this.baseInfoForm.workStartDate) > 45*365*24*60*60*1000) {
                            callback(new Error("请输入真实的工作经历时间"));
                        } else {
                            callback();
                        }
                    } else {
                        if (moment(this.baseInfoForm.workEndDate) - moment(this.baseInfoForm.workStartDate) > 45*365*24*60*60*1000) {
                            callback(new Error("请输入真实的工作经历时间"));
                        } else {
                            callback();
                        }
                    }
                } else {
                    callback();
                }
            }
        },
        validEducationDateRange(rule, value, callback) {
            if (this.isPersonalResume) {
                callback();
                return;
            } else {
                if (value == '') {
                    callback(new Error('请输入教育经历时间'));
                    return;
                }

                if (this.baseInfoForm.educationStartDate && this.baseInfoForm.educationEndDate) {
                    if (this.baseInfoForm.educationEndDate.getFullYear() == '9999') {
                        if (moment() - moment(this.baseInfoForm.educationStartDate) < 365*24*60*60*1000) {
                            callback(new Error("请输入真实的教育经历时间"));
                        } else {
                            callback();
                        }
                    } else {
                        if (moment(this.baseInfoForm.educationEndDate) - moment(this.baseInfoForm.educationStartDate) < 365*24*60*60*1000) {
                            callback(new Error("请输入真实的教育经历时间"));
                        } else {
                            callback();
                        }
                    }
                } else {
                    callback();
                }
            }
        },
        handleCancel() {
            this.dialogVisible = false;
            this.$refs.baseInfoForm.workDateRange = '';
            this.$refs.baseInfoForm.educationDateRange = '';
            this.$refs.baseInfoForm.resetFields();
            this.$refs.baseInfoForm.clearValidate();
            this.hasWorkModified = false;
            this.hasEducationModified = false;
        },
        getAgeFromBirthday(birthday) {
            if(birthday == '' || birthday.indexOf('0001') == 0) {
                return '';
            }
            return (new Date()).getFullYear() - birthday.slice(0, 4);
        },
        confirmHandle() {
            console.log('is subit');
            this.formLoading = true;
            this.$refs.baseInfoForm.validate((validator) => {
                if(validator) {
                    let params = Object.assign({}, this.baseInfoForm),
                        editUrl = "";
                    params = {
                        ...params,
                        customerDepartmentId: params.customerDepartmentId || 0,
                        customerId: params.customerId || '',
                    }
                    delete params['customerDepartmentName'];

                    if ((this.mobileStatus == 1 || this.mobileStatus == 2) && params.mobile == '******') {
                        params.mobile = '';
                    }
                    
                    let age = params.age;
                    if(age && this.getAgeFromBirthday(this.originBirthday) != age) {
                        if(this.originBirthday.indexOf('0001') == 0) {
                            let str = moment().format('-MM-DD');
                            let year = parseInt(moment().format('YYYY')) - age;
                            params.birthday = year + str + 'T00:00:00';
                            delete params.age;
                        }else {
                            let str = this.originBirthday.slice(4);
                            let year = parseInt(moment().format('YYYY')) - age;
                            params.birthday = year + str;
                            delete params.age;
                        }
                    }
                    if(this.isSaveToDb) {
                        if(this.isPersonalResume) {
                            editUrl = candidateUrl.candidate_simple_edit.replace(/\%p/g, this.baseInfoForm.resumeId);
                        } else {
                            editUrl = candidateUrl.candidate_simple_edit.replace(/\%p/g, this.baseInfoForm.id);
                        }
                        this.formLoading = true;
                        _request({
                            url: editUrl,
                            method: "POST",
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded'
                            },
                            data: params
                        }).then(res =>{
                            this.formLoading = false;
                            this.dialogVisible = false;
                            this.$emit('candidate-detail-refresh');
                        }).catch(err => {
                            this.formLoading = false;
                        });
                    } else {
                        this.dialogVisible = false;
                        this.$emit('save-btn-callback', params);
                    }
                } else {
                    this.formLoading = false;
                    console.log("error submit");
                }
            })
        }
    }
}
</script>

<style lang="scss" scope>
.base-info-form {
    padding: 0 4px;

    .row-title {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        color: #333;
        margin-bottom: 16px;

        &.work-row-title {
            margin-top: 10px;
            height: 32px;

            .checkbox-item {
                display: flex;
                align-items: center;
                height: 100%;
                margin-left: 20px;
                margin-bottom: 0;

                .el-checkbox__label {
                    font-size: 14px;
                    font-weight: normal;
                    color: #666;
                    padding-left: 8px;
                }

                .el-checkbox__inner {
                    transform: scale(1.2);
                    vertical-align: top;
                }
            }
        }

        > span {
            display: inline-block;
            width: 4px;
            height: 22px;
            margin-right: 12px;
            background-color: $primary;
        }
    }

    .date-picker-pannel{
        display: flex;
        align-items: center;
        width: 100%;
        height: 40px;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        overflow: hidden;
        padding-left: 10px;

        .el-input{
            width: 100%;
        }

        .el-input__inner{
            border: none;
        }

        .el-date-editor .el-input__inner{
            border: none;
        }

        .start-date-wrap, .end-date-wrap{
            .el-date-editor.el-input{
                width: 100%;
            }
            .el-input__icon{
                right: 20px;
            }
        }

        .date-picker-wrap{
            flex: 1;
            display: inline-block;
            position: relative;
            .el-date-editor{
                .el-input__prefix{
                    display: none;
                }
                .el-input__inner{
                    text-align: center;
                    &:hover, &:focus {
                        border: none;
                    }
                }
            }
            .el-input--prefix .el-input__inner{
                padding-left: 10px;
            }
            .el-input--suffix .el-input__inner{
                padding-right: 10px;
            }
            
            .date-tonow{
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background: #fff;
                line-height: 40px;
                text-align: center;
                font-size: 14px;
            }

            .opacity-date-picker{
                .el-input__inner{
                    opacity: 0;
                    z-index: 2;
                }
            }
        }

        .font-icon, .date-picker-split {
            flex-shrink: 0;
        }

    }

    .require-tip {
        color: red;
        margin-left: 5px;
        vertical-align: middle;
    }
    .el-form-item {
        margin-bottom: 16px;
        .el-form-item__label {
            margin-bottom: 0px;
        }
    }
    .el-form-item__content {
        .text-slot {
            position: absolute;
            top: 0;
            right: 0;
            width: 30px;
            z-index: 10;
            text-align: center;
        }

        .el-input__inner {
            padding: 0 30px 0 12px;
        }
        .el-form-item__error {
            padding-top: 2px;
            white-space: nowrap;
        }

        .view-mobile-btn {
            position: absolute;
            top: 50%;
            right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 24px;
            line-height: 24px;
            text-align: center;
            margin-top: -12px;
            padding: 0 6px;
            font-size: 12px;
            color: $primary;
            border-radius: 3px;
            background-color: #E5F6F1;
            cursor: pointer;

            &.lock {
                background-color: transparent;
            }
        }
    }
    .checkbox-form-item.el-form-item {
        margin-bottom: 16px;
        .el-form-item__content {
            line-height: 28px;
        }
        .checkbox-item {
            margin-bottom: 0px;
            line-height: 28px;
            height: 28px;
        }

        .add-work-tip {
            font-size: 12px;
            color: #888;
            margin-left: 10px;
        }
    }
}
.gender-form-item {
    .el-form-item__content {
        height: 40px;
    }
    .gender-select-btn.el-button {
        line-height: 38px;
        height: 38px;
        background: #fff !important;
        &.active{
            background: $primary !important;
            color: #fff !important;
        }
    }
}
.select-form-item {
    .el-cascader {
        width: 100%
    }
}
.el-form-item.yexp-form-item {
    .el-form-item__content{
        .el-input {
            .el-input__inner {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }
            .el-input-group__append {
                background: inherit;
                padding: 0 10px;
                border: none;
                color: #606266;
            }
        }
        .el-form-item__error {
            left: -6px;
        }
    }
}
.university-chose,
.major-chose,
.degree-chose {
    .el-form-item__content {
        .el-select {
            width: 100%;
        }
        .el-input__inner {
            padding-right: 30px;
        }
    }
}
.dialog-footer {
    text-align: right;
    margin-top: 20px;
}
</style>

<style lang="scss">
.select-list-dropdown.el-select-dropdown {
    .el-select-dropdown__item {
        padding: 0 12px;
    }
}
.base-info-edit-dialog {
    .el-dialog__body {
        min-height: 331px;
    }
}
.mb-16{
    margin-bottom: 16px;
    height: 40px;
}

.score-feedback-dialog .tip {
    font-size: 14px;
    color: #333;

    i {
        color: red;
        margin: 0 4px;
    }
}
</style>